<template lang="pug">
page.re(:show-header='false', :show-footer='true')
  template(slot='content')
    page-section.re-section.re-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        img(:src='bannerImgSrc', alt='Rockstar Energy')

    page-section#main.re-section.re-section--enter.page-pb-lg(
      align='center',
      :class='{ "not-started": !isUnderway, "underway": isUnderway, "ended": isEndedView }'
    )
      template(slot='content')
        //- Loading
        loading-icon.page-mt-lg-alt.page-mb-xxl(v-if='isLoading', size='lg', color='re-gold')

        //- Not started
        .re-starts-in.page-pt-md.fs-xs(v-else-if='!isUnderway', class='sm:fs-md')
          giveaway-starts-in.bg-color-re-gold.px-3.py-1.rounded-sm(
            :giveaway='giveaway',
            label='Starts In:'
            countdown-color='white'
          )

        //- Active View
        giveaway-embed(
          v-else-if='!isEndedView',
          :id-token='idToken',
          :hide-countdown='false',
          :hide-footer='false',
          height='1100'
        )

        //- Ended View
        .re-ended-view(v-else)
          .re-ended-view-inner.d-inline-block.page-px-sm.page-py-sm.bg-color-re-black.mx-auto.rounded
            h1 Thanks to everyone who entered!
            .fs-normal View Winners
            giveaway-tile.mt-4.pb-3.mx-auto(
              :giveaway='giveaway',
              :show-title='false',
              :show-prizes='false',
              :show-dates='false',
              :show-state='false'
            )
</template>
<script>
import GiveawayEmbed from '../components/GiveawayEmbed'
import GiveawayStartsIn from '../components/GiveawayStartsIn'
import fetchGiveawaysMixin from '../mixins/fetch-giveaways'
import gaEventMixin from '../mixins/ga_event'

export default {
  name: 'RockstarEnergy',
  mixins: [fetchGiveawaysMixin, gaEventMixin],
  data() {
    return {
      isLoading: true,
      isUnderway: false,
      isEndedView: false,
      idToken: '_X3TcPL',
      giveaway: null,
      activeGiveawayInterval: null,
      bannerImgSrc: require('../assets/themes/rockstar-energy/images/banner-full-cans2.png')
    }
  },
  watch: {
    isEndedView() {
      if (this.isEndedView) {
        clearInterval(this.activeGiveawayInterval)
      }
    }
  },
  async created() {
    this.giveaway = await this.fetchGiveawayByIdToken(this.idToken)
    this.activeGiveawayCheck()
    this.activeGiveawayInterval = setInterval(this.activeGiveawayCheck, 1000)
    this.isLoading = false
  },
  beforeDestroy() {
    clearInterval(this.activeGiveawayInterval)
  },
  methods: {
    activeGiveawayCheck() {
      if (!this.giveaway) {
        return
      }

      this.isUnderway = this.giveaway.isActive || this.giveaway.isEnded
      this.isEndedView = this.giveaway.isEnded
    }
  },
  metaInfo() {
    return {
      title: 'Rockstar Energy Giveaway!',
      titleTemplate: '%s | Surf Giveaways'
    }
  },
  components: {
    GiveawayEmbed,
    GiveawayStartsIn
  }
}
</script>
<style lang="scss" src="../assets/themes/rockstar-energy/scss/main.scss" />
