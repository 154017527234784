<template lang="pug">
.surf-embed-wrap
  .surf-embed-inner(:style='innerStyles')
    iframe.surf-embed(:src='embedUrl', allowfullscreen='yes', scrolling='yes')
</template>
<script>
export default {
  props: {
    idToken: {
      type: String,
      required: true
    },
    hideCountdown: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: null // in px
    }
  },
  computed: {
    embedUrl() {
      const baseEmbedUrl = `${this.$helpers.giveawayUrl(this.idToken)}/embed`
      const params = new URLSearchParams()

      if (this.hideCountdown) {
        params.set('hide-countdown', 1)
      }

      if (this.hideFooter) {
        params.set('hide-footer', 1)
      }

      return params.toString() ? `${baseEmbedUrl}/?${params.toString()}` : baseEmbedUrl
    },
    innerStyles() {
      if (this.height) {
        return {
          height: `${this.height}px`
        }
      }

      return null
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';
$max-width: $base-spacing-100 * 5.3;
$height: $base-spacing-100 * 6.5;

.surf-embed {
  height: 100%;
  width: 100%;
  min-width: 100%;
  color: $color-white;
  border: none;
  border-radius: $base-spacing-10;

  &-wrap {
    @media (max-width: $max-width + $base-spacing-10 * 5) {
      padding-left: $base-spacing-10 * 2;
      padding-right: $base-spacing-10 * 2;
    }
  }

  &-inner {
    position: relative;
    height: $height;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    line-height: 0;
    border-radius: $base-spacing-10;
    box-shadow: 0 3px 10px 3px rgba(15, 15, 15, 0.64);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @media (min-width: $breakpoint-md) {
      overflow: hidden;
    }
  }
}
</style>
